import { ReactElement } from 'react';
import classNames from 'classnames';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import './cart.style.scss';

interface CartLayoutProps {
    children: ReactElement | JSX.Element;
    backgroundImage: any;
    nodeTitle: string;
    title: string;
    eyebrowText: string;
    variant: 'confirmation' | 'review';
    isHideHeader?: boolean;
}

const CartLayout = ({
    children,
    backgroundImage,
    nodeTitle,
    title,
    eyebrowText,
    variant,
    isHideHeader = false
}: CartLayoutProps) => {
    return (
        <WorkflowLayout
            backgroundImage={backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle }}
            className={classNames(
                'cart-order-confirmation-layout-form-wrapper',
                `cart-order-confirmation-layout-form-wrapper__${variant}`
            )}
        >
            {!isHideHeader ? (
                <WorkflowLayoutFormWrapper
                    title={title}
                    eyebrowText={eyebrowText}
                    className="cart-order-confirmation-layout-form-wrapper"
                    icon={<CartIcon className={'header-icon-container'} />}
                >
                    {children}
                </WorkflowLayoutFormWrapper>
            ) : (
                <>{children}</>
            )}
        </WorkflowLayout>
    );
};

export default CartLayout;
